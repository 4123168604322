import { TableRow, TableCell, IconButton, TextField, Box } from '@mui/material';
import { CheckCircleIcon } from '@primer/octicons-react';
import { useState } from 'react';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { ConfigItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
// import StyledButton from '../../../../../widgets/styledButton/StyledButton';
// import SwitchButton from '../../../../../widgets/switchButton/SwitchButton';
import { ConfigKeys } from './constants';

const ConfigRow = ({
  configData,
  selectedConfig,
  handleSelect,
  handleDeselect,
  handleUpdate,
  setConfigToDelete,
  loading,
}: {
  configData: ConfigItem;
  selectedConfig?: number;
  handleSelect: (itemToSelect: ConfigItem) => void;
  handleDeselect: () => void;
  handleUpdate: (config: ConfigItem) => void;
  setConfigToDelete: React.Dispatch<React.SetStateAction<ConfigItem | null>>;
  loading?: boolean;
}) => {
  const [config, setConfig] = useState<ConfigItem>({ ...configData });

  const discardChanges = () => {
    setConfig({ ...configData });
    handleDeselect();
  };

  return (
    <TableRow
      sx={{
        opacity: selectedConfig
          ? config.id === selectedConfig
            ? '100%'
            : '25%'
          : '100%',
      }}
    >
      <TableCell
        align="center"
        sx={{
          fontWeight: '900',
        }}
      >
        {config.id}
      </TableCell>
      <TableCell align="center">
        {config.id === selectedConfig ? (
          <TextField
            size="small"
            fullWidth
            onChange={(event) =>
              setConfig({ ...config, key: event.target.value })
            }
            value={config.key}
          />
        ) : ConfigKeys[config.key] ? (
          ConfigKeys[config.key].title
        ) : (
          config.key
        )}
      </TableCell>
      <TableCell align="center" width="50%">
        {config.id === selectedConfig ? (
          <TextField
            size="small"
            fullWidth
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => setConfig({ ...config, value: event.target.value })}
            value={config.value}
          />
        ) : (
          <Box display={'flex'} flexDirection={'column'}>
            {ConfigKeys[config.key] && ConfigKeys[config.key].valueParser
              ? ConfigKeys[config.key].valueParser(config.value)
              : config.value}
          </Box>
        )}
      </TableCell>
      <TableCell>
        {config.id === selectedConfig ? (
          <IconButton
            aria-label="discard changes"
            size="small"
            color="error"
            onClick={discardChanges}
          >
            {MaterialIcons.Close}
          </IconButton>
        ) : (
          <IconButton
            size="small"
            aria-label="edit config"
            onClick={() => handleSelect(config)}
            disabled={!!selectedConfig}
          >
            {MaterialIcons.Edit}
          </IconButton>
        )}
      </TableCell>
      <TableCell>
        {config.id === selectedConfig ? (
          <IconButton
            color="success"
            size="small"
            aria-label="save changes"
            onClick={() => handleUpdate(config)}
            disabled={loading}
          >
            <CheckCircleIcon size={16} />
          </IconButton>
        ) : (
          <IconButton
            color="error"
            size="small"
            aria-label="delete config"
            disabled={!!selectedConfig}
            onClick={() => setConfigToDelete(config)}
          >
            {MaterialIcons.Delete}
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ConfigRow;
